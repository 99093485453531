import { init } from '@jill64/sentry-sveltekit-cloudflare/client';
import { PUBLIC_API_BASE_URL, PUBLIC_SENTRY_DSN } from '$env/static/public';
import { setApiBaseUrl } from '@luxfit/backend-api';

setApiBaseUrl(PUBLIC_API_BASE_URL);

const onError = init(PUBLIC_SENTRY_DSN, {
    sentryOptions: {}
});

export const handleError = onError();
